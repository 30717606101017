import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b39b42fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-grid" }
const _hoisted_2 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_3 = { for: "name" }
const _hoisted_4 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_5 = { for: "type" }
const _hoisted_6 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_7 = { class: "p-field p-col-12 p-fluid" }
const _hoisted_8 = { for: "shoppingUrl" }
const _hoisted_9 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_10 = { class: "p-field p-col-6 p-fluid" }
const _hoisted_11 = { class: "dicount-code__active" }
const _hoisted_12 = { for: "activeDiscountCode" }
const _hoisted_13 = {
  key: 0,
  class: "p-field p-col-6 p-fluid"
}
const _hoisted_14 = { for: "discountCode" }
const _hoisted_15 = {
  key: 0,
  class: "p-invalid"
}
const _hoisted_16 = { class: "p-field p-col-12 p-fluid" }
const _hoisted_17 = { for: "description" }
const _hoisted_18 = {
  key: 0,
  class: "p-invalid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.showDialog) = $event)),
    header: _ctx.$t('supplementation.create-modal.title'),
    modal: true
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('common.cancel'),
        class: "p-button p-component p-button-secondary p-button-medium",
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showDialog = false))
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        label: _ctx.$t('common.save'),
        class: "p-button p-component p-button-info p-button-medium",
        loading: _ctx.waitSubmit,
        onClick: _ctx.onSubmit
      }, null, 8, ["label", "loading", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('supplementation.create-modal.subtitle')), 1),
      _createElementVNode("form", null, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('supplementation.create-modal.name')) + "*", 1),
            _createVNode(_component_InputText, {
              id: "name",
              modelValue: _ctx.supplementFormDto.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.supplementFormDto.name) = $event)),
              modelModifiers: { trim: true },
              required: "true",
              class: _normalizeClass({ 'p-invalid': _ctx.showValidation && !_ctx.validateForm.name.valid })
            }, null, 8, ["modelValue", "class"]),
            (_ctx.showValidation && !_ctx.validateForm.name.valid)
              ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.$t(`${_ctx.validateForm.name.message}`)), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["p-field p-col-6 p-fluid", { 'p-disabled': _ctx.modify }])
          }, [
            _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('supplementation.create-modal.type')) + "*", 1),
            _createVNode(_component_Dropdown, {
              id: "type",
              modelValue: _ctx.supplementFormDto.type,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.supplementFormDto.type) = $event)),
              options: _ctx.typesOptions,
              "option-label": "label",
              "option-value": "value",
              class: _normalizeClass({ 'p-invalid': _ctx.showValidation && !_ctx.validateForm.type.valid })
            }, null, 8, ["modelValue", "options", "class"]),
            (_ctx.showValidation && !_ctx.validateForm.type.valid)
              ? (_openBlock(), _createElementBlock("small", _hoisted_6, _toDisplayString(_ctx.$t(`${_ctx.validateForm.type.message}`)), 1))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('supplementation.create-modal.shoppingUrl')), 1),
            _createVNode(_component_InputText, {
              id: "shoppingUrl",
              modelValue: _ctx.supplementFormDto.shoppingUrl,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.supplementFormDto.shoppingUrl) = $event)),
              modelModifiers: { trim: true },
              required: "true",
              class: _normalizeClass({ 'p-invalid': _ctx.showValidation && !_ctx.validateForm.shoppingUrl.valid })
            }, null, 8, ["modelValue", "class"]),
            (_ctx.showValidation && !_ctx.validateForm.shoppingUrl.valid)
              ? (_openBlock(), _createElementBlock("small", _hoisted_9, _toDisplayString(_ctx.$t(`${_ctx.validateForm.shoppingUrl.message}`)), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_InputSwitch, {
                modelValue: _ctx.activeDiscountCodeRef,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.activeDiscountCodeRef) = $event)),
                inputId: "activeDiscountCode"
              }, null, 8, ["modelValue"]),
              _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('supplementation.create-modal.discountCode')), 1)
            ])
          ]),
          (_ctx.activeDiscountCodeRef)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('supplementation.create-modal.discountCode')) + "*", 1),
                _createVNode(_component_InputText, {
                  id: "discountCode",
                  modelValue: _ctx.supplementFormDto.discountCode,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.supplementFormDto.discountCode) = $event)),
                  modelModifiers: { trim: true },
                  required: "true",
                  class: _normalizeClass({ 'p-invalid': _ctx.showValidation && !_ctx.validateForm.discountCode.valid })
                }, null, 8, ["modelValue", "class"]),
                (_ctx.showValidation && !_ctx.validateForm.discountCode.valid)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_15, _toDisplayString(_ctx.$t(`${_ctx.validateForm.discountCode.message}`)), 1))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('supplementation.create-modal.description')), 1),
            _createVNode(_component_Textarea, {
              id: "description",
              modelValue: _ctx.supplementFormDto.description,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.supplementFormDto.description) = $event)),
              class: _normalizeClass({ 'p-invalid': _ctx.showValidation && !_ctx.validateForm.description.valid }),
              rows: "5",
              "auto-resize": true,
              type: "text"
            }, null, 8, ["modelValue", "class"]),
            (_ctx.showValidation && !_ctx.validateForm.description.valid)
              ? (_openBlock(), _createElementBlock("small", _hoisted_18, _toDisplayString(_ctx.$t(`${_ctx.validateForm.description.message}`)), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}